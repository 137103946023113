<template>
    <div class="incoming-route">
        <clouds v-if="welcomeTheme === 'CLOUDS'" />
        <astypalaia v-else-if="welcomeTheme === 'ASTYPALAIA'" />
        <clouds-with-gifts v-else />
        <div class="incoming-container">
            <div class="logo-container">
                <img
                    :src="branding.primaryLogoUrl"
                    class=""
                    alt=" "
                >
            </div>
            <div v-if="successMessage" class="action-text">
                {{ successMessage }}
            </div>
            <div v-if="errorMessage" class="action-text">
                {{ errorMessage }}
            </div>
        </div>
    </div>
</template>

<script>
    import CloudsWithGifts from '@/components/welcome-backgrounds/CloudsWithGifts'
    import Clouds from '@/components/welcome-backgrounds/Clouds'
    import Astypalaia from '@/components/welcome-backgrounds/Astypalaia'
    import playerService from '@/services/player-service'
    export default {
        components: {
            CloudsWithGifts,
            Clouds,
            Astypalaia
        },
        data() {
            return {
                successMessage: '',
                errorMessage: ''
            }
        },
        computed: {
            branding() {
                return this.$store.getters.getBranding
            },
            welcomeTheme() {
                return this.$store.getters.getWelcomeTheme
            }
        },
        async beforeMount() {
            if (this.$route.query.player && this.$route.query.challenges) {
                try {
                    await playerService.challengeUnsubscribe(this.$route.query.player)
                    this.successMessage = 'Challenges Unsubscribe Successful'
                } catch (error) {
                    this.errorMessage = 'Unsubscribe Failed. Please try again later.'
                }
            } else if (this.$route.query.player) {
                try {
                    await playerService.unsubscribe(this.$route.query.player)
                    this.successMessage = 'Unsubscribe Successful'
                } catch (error) {
                    this.errorMessage = 'Unsubscribe Failed. Please try again later.'
                }
            }
        }
    }
</script>

<style scoped>
.incoming-route {
    width: 100%;
    min-height: 100vh;
    display: grid;
    place-items: center;
    position: relative;
}

.incoming-container {
    color: var(--white);
    z-index: 1;
}

.logo-container {
    margin: 0 auto;
}

.logo-container img {
    height: 90px;
}

.action-text {
    margin-top: 3rem;
    font-size: 1.75rem;
    text-shadow: 0 0 10px rgb(0 0 0 / 50%);
}

@media screen and (min-width: 768px) {
    .action-text {
        margin-top: 7rem;
        font-size: 2.5rem;
    }
}

@media screen and (min-width: 1100px) {
    .action-text {
        margin-top: 8rem;
        font-size: 2.75rem;
    }
}

</style>
